.outer-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-wrapper {
  color: #fff;
  background: #333;
  padding: 2rem;
  text-align: center;
  width: 100%;
}
