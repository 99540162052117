.range_temperature {
    -webkit-appearance: none;
    width: 6rem;
    height: 4px;
    background: #727EAB;
    border-radius: 5px;
    background-image: linear-gradient(#EF5E10, #EF5E10);
    background-size: 70% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

/* Input Thumb */
.range_temperature::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #EF5E10;
    cursor: ew-resize;
}

.range_temperature::-moz-range-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #EF5E10;
    cursor: ew-resize;
}

.range_temperature::-ms-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #EF5E10;
    cursor: ew-resize;
}